/************************************************************************************************
 * start homepage sass
 */

.hideme{
	opacity: 0;
}

.mainWrapper{
	height: 100%;
}

.textPrimary{
	line-height: 1em;
	font-size: 3.5em;
	@media #{$lg}{
	font-size: 2em;
	}

	font-weight: bold;
}

.header{
	height: 80%;
	width: 100%;
	background-image:url('../img/hobbit.jpg');
	background-size:cover;
	background-repeat:no-repeat;
	background-position:top;
	background-blend-mode: multiply;
	background-attachment: fixed;

	.constrain{
		position:relative;
		height: 100%;
		padding:0 20px;
		.mainCalloutContainer{
			position: absolute;
				@media #{$md}{
					position: relative;
				}
			top: 50%;
			transform: translate(0,-50%);
			.mainCallout{
				position: relative;
				top: 50%;
				max-width: 50%;
				@media #{$md}{
					max-width: 100%;
					text-align: center;
				}
				button{
					width: 200px;
					@media #{$md}{
						margin: 0 auto
					}
				}
			}
		}
	}
}
.sectionPadding{
	padding: 100px 0px;
}
.services{
	@media #{$md}{
		padding-right: 20px;
		padding-left: 20px;
	}

	text-align: center;
	.cardBox{
		text-align: left;
		margin: 0 auto;
		width: calc(50% - 20px);
		position: relative;
		@media #{$md}{
			width: 100%;
			margin:0 0 10px 0;
		}
		-webkit-box-shadow: 0 1px 0 1px rgba(0,0,0,0.05);
		-moz-box-shadow: 0 1px 0 1px rgba(0,0,0,0.05);
		box-shadow: 0 1px 0 1px rgba(0,0,0,0.05);
		background-color: #ffffff;
		display: inline-block;
		margin: 10px 10px;
		.contentContainer{
			width: 100%;
			min-height: 250px;
			position:relative;
			border: 1px solid $primary;
			border-radius:5px;
		}
		.left{
			width: 10%;
			height: 100%;
			position: absolute;
			img,.icon{
				width: 40px;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%);
				font-size: 2em;
				text-align: center;
			}
		}
		.right{
			width: 90%;
			padding-left: 10%;
			.rightText{
				padding:10px;
				position:absolute;
				top: 50%;
				transform: translateY(-50%);
				width: calc(90% - 20px);
			}
		}
	}
}
.about{
	@media #{$md}{
		.constrain{
			padding:0 20px;
		}
	}
	background-image:url('../img/mapBlur.jpg');
	background-size:cover;
	background-repeat:no-repeat;
	background-position:top;
	background-blend-mode: multiply;
	background-attachment: fixed;

	text-align: center;

	ul{
		padding:0;
		list-style-type: none;
		li{
			display: inline-block;
			width: 30%;
		}
	}

	.left{
		width: 50%;
		@media #{$md}{
			width: 100%;
			display: block;
			padding:0;
		}
	}
	.right{
		width: 50%;
		@media #{$md}{
			width: 100%;
			display: block;
			padding:0;
			margin-top: 20px;
			-webkit-box-shadow: none;
			-moz-box-shadow: none;
			box-shadow: none;
			background-color: transparent;
		}
	}

}
.ourTeam{
	background-color:$white;
}

.leftContent{
	width: 100%;
	.mainText{
		text-align: center;
	}
	.table{
		display: table;
		width: 100%;
	}
	.left{
		width: 50%;
		@media #{$md}{
			width: 100%;
		}
		display: table-cell;
		vertical-align: middle;
		.leftConstrain{
			width: $constrainWidth/2;
			@media #{$md}{
				width: 100%;
			}
			float: right;
		}
	}
	.right{
		width: 50%;
		display: table-cell;
		.rightConstrain{
			text-align: center;
			max-width:$constrainWidth/2;
			@media #{$md}{
				width: 100%;
			}

			img{
				width: 50%;
			}
		}
	}
}

.contact{
	background-image:url('../img/bback.jpg');
	background-size:contain;
	background-repeat:no-repeat;
	background-position:center;
	@media #{$sm}{
	background-size:cover;
	}

	@media #{$md}{
		.constrain{
			padding:0 20px;
		}
	}
	form{
		max-width: 600px;
		margin: 0 auto;
	}
	input{
		width: 50%;
		float: left;
	}
	#company{
		width: 100%;
	}
	button{
		width: auto;
		padding: 10px;
		float: right;
	}
}

.footer{
	@media #{$md}{
		.constrain{
			padding:0 20px;
		}
	}
	background-color:$lightFade;
	.logo{
		float: left;
		width: 50%;
		img{
			height: 100px;
			position: relative;
			-webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
			filter: grayscale(100%);
			opacity: .2;
			@media #{$md}{
				display: none;
			}
		}
	}
	.address{
		float: left;
		width: 50%;
		@media #{$md}{
			width: 100%;
		}
	}
}

.credits{
	color:#ffffff;
	background-color:$secondary;
	padding:5px;
	a{
		text-decoration: none;
		color: inherit;
	}
	a:hover{
		color: $primary;
	}
}

.social{
	ul{
		list-style: none;
		margin: 0;
	}
	li{
		display: inline-block;
		a{
			color: inherit;
			text-decoration: none;
		}
		&:hover{
			color: $primary;
		}
	}
}
/**
 * end homepage sass
 ***********************************************************************************************/