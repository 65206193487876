/************************************************************************************************
 * start colors sass
 */
$colors:(
	Primary:#007BFF,
	Secondary:#6C757D,
	Warning:#A75528,
	Danger:#DC3545,
	Success:#28A745,
	White:#FFFFFF,
	Black:#000000,
	Dark:#333333,
	Faded:#90949c,
	LightFade:#F6F6F6
);

$primary:getColor('Primary',$colors);
$secondary:getColor('Secondary',$colors);
$warn:getColor('Warning',$colors);
$danger:getColor('Danger',$colors);
$success:getColor('Success',$colors);
$white:getColor('White',$colors);
$black:getColor('Black',$colors);
$dark:getColor('Dark',$colors);
$faded:getColor('Faded',$colors);
$lightFade:getColor('LightFade',$colors);

/* For each key in the map, created an own class */
@each $name, $value in $colors {
	.c#{$name} {
		color: $value;
	}
	.bg#{$name} {
		background-color: $value;
	}

	//create fade buttons for each color
	@if($value==#FFFFFF){
		.fadeBtn#{$name}{
			background-color: $value;
			border: 2px solid $value;
			color: $dark;
			&:hover{
				background-color: darken($value, 10%);
				color: $dark;
			}
		}
		.fadeBtn#{$name}Outline{
			border: 2px solid $value;
			color: $value;
			background-color: transparent;
			&:hover{
				background-color:$value;
				color: $primary;
			}
		}
	}@else{
		.fadeBtn#{$name}{
			background-color: $value;
			border: 2px solid $value;
			color: #FFFFFF;
			&:hover{
				background-color: darken($value, 10%);
				color: #FFFFFF;
			}
		}
		.fadeBtn#{$name}Outline{
			border: 2px solid $value;
			color: $value;
			background-color: transparent;
			&:hover{
				background-color:$value;
				color: #FFFFFF;
			}
		}
	}
}
/**
 * end colors sass
 ***********************************************************************************************/