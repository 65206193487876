/************************************************************************************************
 * start general sass
 */
html, body {
color: $dark;
font-family:Poppins, Source Sans Pro, Helvetica, Arial, sans-serif;
font-size:1em;
line-height: 1.6em;
margin:0;
padding:0;
width: 100%;
height: 100%;
}
div,p,h1,h2,h3,h4,h5{
margin: 0;
padding: 0;
font-size:1em;
}

.vCenter {
    display: inline-block;
    vertical-align: middle;
    float: none;
}

.fadeBtn {
	-moz-border-radius: 3px;
	-webkit-border-radius: 3px;
	-webkit-tap-highlight-color: rgba(255, 255, 255, 0);
	-webkit-transition-duration: 0.4s;
	border-radius: 3px;
	box-sizing: border-box;
	cursor: pointer;
	display: block;
	margin: 4px 0px;
	padding: 8px 0px;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	transition-duration: 0.4s;
	width: 100%;
	font-size: 1em;
}

.fsNormal{
font-size: 1em;
}
.fsBig{
font-size: 2em;
line-height: 1em;
}
.fsSmall{
font-size: .8em;
}

hr{
display: block;
height: 1px;
border: 0;
border-top: 1px solid #EEEEEE;
margin: 1em 0;
padding: 0;
}

.fwBold{
font-weight: bold;
}
.fwNormal{
font-weight: normal;
}

label{
font-size: 0.8em;
margin-bottom: 5px;
}

.floatRight{
float: right;
}
.floatLeft{
float: left;
}
.clearFix:after {
content: ".";
visibility: hidden;
display: block;
height: 0;
clear: both;
}

.mfInput{
box-sizing: border-box;
height: 40px;
margin: 5px 0px;
padding: 5px;
width: 100%;
font-size: 1em;
}

.constrain {
max-width: $constrainWidth;
margin: 0 auto;
clear: both;
}
.taLeft{
text-align: left;
}
.taRight{
text-align: right;
}
.taCenter{
text-align: center;
}
.taJustify{
text-align: justify;
}
.noPadding{
padding: 0;
}
.noMargin{
margin: 0;
}

.mt{
margin-top: 10px;
}
.mr{
margin-right: 10px;
}
.mb{
margin-bottom:10px;
}
.ml{
margin-left: 10px;
}

.pt{
padding-top: 10px;
}
.pr{
padding-right: 10px;
}
.pb{
padding-bottom: 10px;
}
.pl{
padding-left: 10px;
}

.spacer{
margin: 20px 0px;
}
.spacerPadding{
padding: 20px 0px;
}
.spacerPaddingBottom{
padding-bottom:20px;
}
.spacerPaddingTop{
padding-top:20px;
}
.spacerBottom{
margin-bottom: 20px;
}
.spacerTop{
margin-top: 20px;
}
.spacerRight{
margin-right: 20px;
}
.spacerLeft{
margin-left: 20px;
}
.uc{
text-transform: uppercase;
}
.separatorText {
position: relative;
text-align: center;
}
.separatorText > p{
position: relative;
z-index: 1;
}
.separatorText p span{
padding: 0px 20px;
text-transform: uppercase;
}
.separatorTextLine{
border-bottom:1px solid #dfdfdf;
bottom: 50%;
position: absolute;
width: 100%;
z-index: 0;
}
.loader{
background-color: rgba(0,0,0,0.5);
display: block;
height: 100%;
left: 0;
overflow: hidden;
position: fixed;
top: 0;
width: 100%;
z-index: 9999;
}
.loaderCog{
left: 50%;
position: absolute;
top: 50%;
-webkit-transform: translate(-50%,-50%);
-moz-transform: translate(-50%,-50%);
-ms-transform: translate(-50%,-50%);
-o-transform: translate(-50%,-50%);
transform: translate(-50%,-50%);
}
.card {
-webkit-box-shadow: 0 1px 0 1px rgba(0,0,0,0.05);
-moz-box-shadow: 0 1px 0 1px rgba(0,0,0,0.05);
box-shadow: 0 1px 0 1px rgba(0,0,0,0.05);
background-color: #ffffff;
}
.extraShadow{
-webkit-box-shadow: 0 1px 6px 1px rgba(0,0,0,0.3);
-moz-box-shadow: 0 1px 6px 1px rgba(0,0,0,0.3);
box-shadow: 0 1px 6px 1px rgba(0,0,0,0.3);
}
.rounded{
-webkit-border-radius: 4px 4px 4px 4px;
-moz-border-radius: 4px 4px 4px 4px;
border-radius: 4px 4px 4px 4px;
}

.roundedTop{
-webkit-border-radius: 4px 4px 0 0;
-moz-border-radius: 4px 4px 0 0;
border-radius: 4px 4px 0 0;
}

.roundedBottom{
-webkit-border-radius: 0 0 4px 4px;
-moz-border-radius: 0 0 4px 4px;
border-radius: 0 0 4px 4px;
}

.thinLine {
display: block;
height: 1px;
border: 0;
border-top: 1px solid #EEEEEE;
margin: 0;
padding: 0;
}

.asdf{
border: 1px solid red;
box-sizing: border-box;
-moz-box-sizing: border-box;
-webkit-box-sizing: border-box;
}


/************************************************************
Feature popup
*/

.featurePopupContainer{
position: absolute;
z-index: 999999;
width: 200px;
text-align: left;
padding: 10px;
background-color: #000;
}

.featurePopupContainer p{
display: inline-block;
width: 100%;
}

.featurePopupTitle{
font-weight: bold;
color: #ffffff;
margin: 10px 0px 0px 0px;
}
.featurePopupDescription{
margin: 10px 0px 0px 0px;
}
.featurePopupBody{
display: inline-block;
width: 100%;
}
.featureButtons{
}
.featureDismiss{
padding: 10px 0px 10px 10px;
cursor: pointer;
position: relative;
float: right;
bottom: 0;
right: 0;
}
.featureDismiss:hover{
opacity: .8;
}

.triangle{
width: 0;
height: 0;
border-style: solid;
border-width: 20px 20px 0 20px;
border-color: #000000 transparent transparent transparent; /* border top color is the triangle color */
line-height: 0px;
_border-color: #000000 #000000 #000000 #000000; /* border top color is the triangle color */
_filter: progid:DXImageTransform.Microsoft.Chroma(color='#000000');
}

.triangleBottom{
position: absolute;
bottom: -20px;
left: 50%;
-webkit-transform: translateX(-50%);
-moz-transform: translateX(-50%);
-ms-transform: translateX(-50%);
-o-transform: translateX(-50%);
transform: translateX(-50%);
}
/*
End feature popup
************************************************************/

/**
 * end general sass
 ***********************************************************************************************/