/*start nav*/
.nav{
	-webkit-transition:all 0.25s ease-in;
	-moz-transition:all 0.25s ease-in;
	-o-transition:all 0.25s ease-in;
	transition:all 0.25s ease-in;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	color: #FFFFFF;
	z-index: 100;
	height: 50px;

	&.collapsed{
		height: 50px;
		.mobile{
			display: none;
		}
	}
	&.expanded{
		background-color:#FFFFFF;
		color: $primary;
		.navIcon{
			background-image: url('../icons/logo/SVG/blsColor.svg');
		}
		height: 125%; //make bigger than 100% to compensate for chrome browser on mobile
		.mobile{
			display: block;
			width: 100%;
		}
		li:hover{
			color: #FFFFFF;
		}
	}

	a{
		color: inherit;
	}
	.navSlideButton{
		line-height: 30px;
		padding: 10px 20px;
		margin: 0;
		cursor: pointer;
		float: right;
		display: none;
		@media #{$sm}{
			display: block;
		}
	}
	.navSlideButton:hover{
		background-color:#FFFFFF;
		color: $primary;
	}
	.navConstrain{
		max-width: $constrainWidth;
		margin: 0 auto;
	}
	.iconContainer{
		position: absolute;
		height: 100%;
	}
	.constrain{
		position: relative;
		top: 0;
		left: 0;
		height: 100%;
	}
	.navIcon{
		background-image: url('../icons/logo/SVG/blsLight.svg');
		height: 40px;
		width: 40px;
		float: left;
		top: 5px;
		position: relative;
		background-repeat:no-repeat;
		background-position:center;
	}
	.navList{
		float: right;
		list-style: none;
		line-height: 50px;
		top: 0;
		margin: 0;
		padding: 0;
		@media #{$sm}{
			overflow-y: scroll;
			max-height: calc(100vh - 50px);
		}
		li{
			display: inline-block;
			padding: 0 10px;
		}
		li:hover{
			background-color:darken($primary, 10%);
			cursor: pointer;
		}
		@media #{$sm}{
			ul{
				top: 50px;
				width: 100%;
				position: absolute;
				height: 50%;
				overflow-y: scroll;
			}
			li{
				text-align: right;
				width: calc(100% - 20px);
				padding:0 10px;
			}
		}
	}
}



.scrolled{
	color: $primary;
	background-color:#FFFFFF;

	-webkit-transition:all 0.25s ease-in;
	-moz-transition:all 0.25s ease-in;
	-o-transition:all 0.25s ease-in;
	transition:all 0.25s ease-in;

	-webkit-box-shadow: 0 1px 2px 1px rgba(0,0,0,0.1);
	-moz-box-shadow: 0 1px 2px 1px rgba(0,0,0,0.1);
	box-shadow: 0 1px 2px 1px rgba(0,0,0,0.1);
	.navIcon{
		background-image: url('../icons/logo/SVG/blsColor.svg');
	}
	li:hover{
		color: #FFFFFF;
	}
	.navSlideButton:hover{
		background-color:$primary;
		color: #FFFFFF;
	}
}
/*end nav*/