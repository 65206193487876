@charset "utf-8";

//@import '../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome.scss';
@import 'settings.scss';
@import 'mixins.scss';
@import 'functions.scss';
@import 'colors.scss';
@import 'general.scss';
/*pages*/
@import 'homepage.scss';
@import 'nav.scss';